import {
	json,
	type DataFunctionArgs,
	type HeadersFunction,
	type LinksFunction,
	type MetaFunction,
} from '@remix-run/node'
import {
	Link,
	Links,
	Meta,
	Outlet,
	Scripts,
	ScrollRestoration,
	useFetchers,
	useLoaderData,
	useMatches,
} from '@remix-run/react'
import { withSentry } from '@sentry/remix'
import { useEffect, useState } from 'react'
import { CookieConsent } from 'react-cookie-consent'
import TagManager from 'react-gtm-module'
import { HoneypotProvider } from 'remix-utils/honeypot/react'
import { z } from 'zod'
import { GeneralErrorBoundary } from './components/error-boundary.tsx'
import { href as iconsHref } from './components/ui/icon.tsx'
import fontStyleSheetUrl from './styles/font.css?url'
import tailwindStyleSheetUrl from './styles/tailwind.css?url'
import { getUserId } from './utils/auth.server.ts'
import { ClientHintCheck, getHints, useHints } from './utils/client-hints.tsx'
import { prisma } from './utils/db.server.ts'
import { getEnv } from './utils/env.server.ts'
import { honeypot } from './utils/honeypot.server.ts'
import { combineHeaders, getDomainUrl } from './utils/misc.tsx'
import { useNonce } from './utils/nonce-provider.ts'
import { useRequestInfo } from './utils/request-info.ts'
import { type Theme, getTheme } from './utils/theme.server.ts'
import { makeTimings, time } from './utils/timing.server.ts'
import { getToast } from './utils/toast.server.ts'

export const links: LinksFunction = () => {
	return [
		// Preload svg sprite as a resource to avoid render blocking
		{ rel: 'preload', href: iconsHref, as: 'image' },
		// Preload CSS as a resource to avoid render blocking
		{ rel: 'preload', href: fontStyleSheetUrl, as: 'style' },
		{ rel: 'preload', href: tailwindStyleSheetUrl, as: 'style' },
		{ rel: 'mask-icon', href: '/favicons/mask-icon.svg' },
		{
			rel: 'alternate icon',
			type: 'image/png',
			href: '/favicons/favicon-16x16.png',
		},
		{ rel: 'apple-touch-icon', href: '/favicons/apple-touch-icon.png' },
		{
			rel: 'manifest',
			href: '/site.webmanifest',
			crossOrigin: 'use-credentials',
		} as const, // necessary to make typescript happy
		//These should match the css preloads above to avoid css as render blocking resource
		{ rel: 'icon', type: 'image/svg+xml', href: '/favicons/favicon.svg' },
		{ rel: 'stylesheet', href: fontStyleSheetUrl },
		{ rel: 'stylesheet', href: tailwindStyleSheetUrl },
	].filter(Boolean)
}

export const meta: MetaFunction<typeof loader> = ({ data }) => {
	return [
		{ title: data ? 'Papelea' : 'Error | Papelea' },
		{
			name: 'description',
			content: `Papelea reúne la información de los trámites de todas las administraciones y la actividad de la comunidad para facilitarte los trámites.Your own captain's log`,
		},
	]
}

export async function loader({ request }: DataFunctionArgs) {
	const timings = makeTimings('root loader')
	const userId = await time(() => getUserId(request), {
		timings,
		type: 'getUserId',
		desc: 'getUserId in root',
	})

	const user = userId
		? await time(
				() =>
					prisma.user.findUniqueOrThrow({
						select: {
							id: true,
							name: true,
							username: true,
							image: { select: { id: true } },
							roles: {
								select: {
									name: true,
									permissions: {
										select: { entity: true, action: true, access: true },
									},
								},
							},
						},
						where: { id: userId },
					}),
				{ timings, type: 'find user', desc: 'find user in root' },
			)
		: null
	if (userId && !user) {
		console.info('something weird happened')
		// something weird happened... The user is authenticated but we can't find
		// them in the database. Maybe they were deleted? Let's log them out.
		//await authenticator.logout(request, { redirectTo: '/' })
	}
	const { toast, headers: toastHeaders } = await getToast(request)
	const honeyProps = honeypot.getInputProps()

	const cookieHeader = request.headers.get('Cookie')
	const existingCookieConsent = cookieHeader
		? cookieHeader
				.split(';')
				.map(cookie => cookie.trim().split('='))
				.find(([name]) => name === 'cookieConsent')?.[1]
		: false

	return json(
		{
			user,
			requestInfo: {
				hints: getHints(request),
				origin: getDomainUrl(request),
				path: new URL(request.url).pathname,
				userPrefs: {
					theme: getTheme(request),
				},
			},
			ENV: getEnv(),
			toast,
			honeyProps,
			existingCookieConsent,
		},
		{
			headers: combineHeaders(
				{ 'Server-Timing': timings.toString() },
				toastHeaders,
			),
		},
	)
}

export const headers: HeadersFunction = ({ loaderHeaders }) => {
	const headers = {
		'Server-Timing': loaderHeaders.get('Server-Timing') ?? '',
	}
	return headers
}

const ThemeFormSchema = z.object({
	theme: z.enum(['system', 'light', 'dark']),
})

/* export async function action({ request }: DataFunctionArgs) {
	const formData = await request.formData()
	const submission = parseWithZod(formData, {
		schema: ThemeFormSchema,
	})
	if (submission.intent !== 'submit') {
		return json({ status: 'idle', submission } as const)
	}
	if (!submission.value) {
		return json({ status: 'error', submission } as const, { status: 400 })
	}
	const { theme } = submission.value

	const responseInit = {
		headers: { 'set-cookie': setTheme(theme) },
	}
	return json({ success: true, submission }, responseInit)
} */

function Document({
	children,
	nonce,
	theme = 'light',
	env = {},
	existingCookieConsent = false,
}: {
	children: React.ReactNode
	nonce: string
	theme?: Theme
	env?: Record<string, string>
	existingCookieConsent?: boolean
}) {
	const { TAG_MANAGER_ID } = env
	const [userConsentToCookies, setUserConsentToCookies] = useState(
		existingCookieConsent,
	)

	useEffect(() => {
		if (
			process.env.NODE_ENV === 'production' &&
			userConsentToCookies &&
			TAG_MANAGER_ID?.length
		) {
			const tagManagerArgs = {
				gtmId: TAG_MANAGER_ID,
			}
			TagManager.initialize(tagManagerArgs)
		}
	}, [TAG_MANAGER_ID, userConsentToCookies])

	const matches = useMatches()
	const triggerUmami =
		!matches.some(m => m.id === 'routes/_appAdmin') &&
		process.env.NODE_ENV === 'production'
	return (
		<html lang="es" className={`${theme} h-full overflow-x-hidden`}>
			<head>
				<ClientHintCheck nonce={nonce} />
				<Meta />
				<meta charSet="utf-8" />
				<meta name="viewport" content="width=device-width,initial-scale=1" />
				<Links />
				{triggerUmami && (
					<script
						defer
						src="https://cloud.umami.is/script.js"
						data-website-id="3ca2adfa-7581-45e1-ad73-34755ae8a372"
					></script>
				)}
			</head>
			<body className="min-h-screen bg-white font-sans">
				{children}
				<script
					nonce={nonce}
					dangerouslySetInnerHTML={{
						__html: `window.ENV = ${JSON.stringify(env)}`,
					}}
				/>
				<ScrollRestoration nonce={nonce} />
				<Scripts nonce={nonce} />
				{/* 
				//Ezoic?Posthog?
				<CookieConsent
					onAccept={() => setUserConsentToCookies(true)}
					onDecline={() => setUserConsentToCookies(false)}
					location="bottom"
					buttonText="Aceptar"
					declineButtonText="Declinar"
					enableDeclineButton
					cookieName="cookieConsent"
					style={{ background: '#1c1c1c' }}
					buttonStyle={{ background: '#F59E0B', fontSize: '13px' }}
					declineButtonStyle={{ background: '#3f3f3f' }}
					expires={365}
					flipButtons
				>
					Este sitio web utiliza cookies para mejorar la experiencia del
					usuario. Consulta nuestra{' '}
					<Link to="/legal" className="text-bold text-amber-700">
						Política de privacidad
					</Link>{' '}
					para obtener más información.
				</CookieConsent> */}{' '}
			</body>
		</html>
	)
}

function AppWithProviders() {
	const data = useLoaderData<typeof loader>()
	const nonce = useNonce()
	//const user = useOptionalUser()
	const theme = useTheme()
	//const isOnSearchPage = matches.find(m => m.id === 'routes/users+/index')
	//const searchBar = isOnSearchPage ? null : <SearchBar status="idle" />

	return (
		<HoneypotProvider {...data.honeyProps}>
			<Document
				nonce={nonce}
				theme={theme}
				env={data.ENV}
				existingCookieConsent={data.existingCookieConsent === 'true'}
			>
				<Outlet />
			</Document>
		</HoneypotProvider>
	)
}

/* const AppExport =
	process.env.NODE_ENV === 'development'
		? (await import('remix-development-tools')).withDevTools(AppWithProviders)
		: AppWithProviders */

export default withSentry(AppWithProviders)

/**
 * @returns the user's theme preference, or the client hint theme if the user
 * has not set a preference.
 */
export function useTheme() {
	const hints = useHints()
	const requestInfo = useRequestInfo()
	const optimisticMode = useOptimisticThemeMode()
	if (optimisticMode) {
		return optimisticMode === 'system' ? hints.theme : optimisticMode
	}
	return requestInfo.userPrefs.theme ?? hints.theme
}

/**
 * If the user's changing their theme mode preference, this will return the
 * value it's being changed to.
 */
export function useOptimisticThemeMode() {
	const fetchers = useFetchers()
	const themeFetcher = fetchers.find(f => f.formAction === '/')

	if (themeFetcher && themeFetcher.formData) {
		const submission = parse(themeFetcher.formData, {
			schema: ThemeFormSchema,
		})
		return submission.value?.theme
	}
}

export function ErrorBoundary() {
	// the nonce doesn't rely on the loader so we can access that
	const nonce = useNonce()

	// NOTE: you cannot use useLoaderData in an ErrorBoundary because the loader
	// likely failed to run so we have to do the best we can.
	// We could probably do better than this (it's possible the loader did run).
	// This would require a change in Remix.

	// Just make sure your root route never errors out and you'll always be able
	// to give the user a better UX.

	return (
		<Document nonce={nonce}>
			<GeneralErrorBoundary />
		</Document>
	)
}
